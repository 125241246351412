import { render, staticRenderFns } from "./VSelect.vue?vue&type=template&id=2b938c8c&scoped=true&"
import script from "./VSelect.vue?vue&type=script&lang=js&"
export * from "./VSelect.vue?vue&type=script&lang=js&"
import style0 from "./VSelect.vue?vue&type=style&index=0&id=2b938c8c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b938c8c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CIcon: require('@chakra-ui/vue').CIcon, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
