<template>
  <c-flex
    w="full"
    justify-content="center"
    align-items="center"
    border-width="1px"
    border-color="gray.200"
    height="62px"
    rounded="sm"
  >
    <select
      v-model="value__"
      v-chakra
      d="inline-block"
      rounded="sm"
      bg="white"
      appearance="none"
      w="full"
      h="full"
      px="15px"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <option value="">{{ placeholder }}</option>
      <slot />
    </select>
    <c-box w="30px">
      <c-icon name="chevron-down" size="20px" />
    </c-box>
  </c-flex>
</template>

<script>
import equal from 'fast-deep-equal'
export default {
  name: "VSelect",
  props: ["placeholder", "value"],
  data() {
    return {
      value__: this.value,
    }
  },
  watch: {
    value(v, vv) {
      if (equal(v, vv)) return;

      this.value__ = v
    },
    value__(v, vv) {
      if (equal(v, vv)) return;
      this.$emit('input', v)
    },
  },
}
</script>

<style scoped>
.dropdown {
  width: 100%;
  height: 62px;
}

.dropdown button {
  height: 100%;
  width: 100%;
}

.dropdown-item {
  height: 62px;
  display: block;
  border: 1px solid grey;
  width: 100%;
}
.dropdown /deep/ .v-popper__wrapper {
  max-height: 250px;
  overflow: scroll;
  border: 1px solid grey;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
}
</style>
