<template>
  <c-box id="listNutritionists">
    <Header/>
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        >
        <c-breadcrumb
          marginTop= "20px"
          marginBottom= "20px"
          v-chakra="{
            'ol': {
              'li': {
                'a': {
                  fontSize: '14px',
                  fontFamily: 'Roboto'
                },
                'span': {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },

          }"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link href="#" color="brand.900" fontSize="14px" fontFammily="Roboto">Beranda</c-breadcrumb-link>
          </c-breadcrumb-item>
          <c-breadcrumb-item>
            <c-breadcrumb-link href="#" color="brand.900" fontSize="14px" fontFammily="Roboto">Manajemen Klien</c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item
            isCurrentPage
            >
            <c-breadcrumb-link
              href="#"
              fontSize= "14px !important"
              fontFammily= "Roboto !important"
              >
              Diet Data Klien
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>

        <c-box w="100%" maxWidth="1200px" mx="auto" marginTop="50px">
          <c-flex flexWrap="wrap" w="100%">
            <c-box w="40%" paddingRight="20px">
              <c-flex 
                w="100%"
                px= "0"
                py= "0"
                >
                <c-box width="72px" height="72px" marginRight="30px">
                  <c-box w="72px" h="72px" overflow="hidden" borderRadius="100%" backgroundColor="lightGray.900">
                    <!-- <c-image
                      :src="require('@/assets/photo.png')"
                      alt="photo"
                    /> -->
                  </c-box>
                </c-box>
                <c-box w="100%">
                  <c-heading as="h3" pos="relative" marginBottom="10px" fontSize="18px" lineHeight="27px" fontWeight="700" color="black.900">
                    Nama Klien
                  </c-heading>
                  <c-text
                    fontFamily="Roboto"
                    fontSize="14px"
                    color="brand.900"
                    marginBottom="20px"
                    >
                    98782629109
                  </c-text>
                </c-box>
              </c-flex>
              <c-list
                v-chakra="{
                  'li': {
                    'a': {
                      color: 'darkGray.900',
                      fontSize: '16px',
                      lineHeight: '24px',
                      padding: '15px 20px',
                      display: 'block',
                      fontWeight: '700',
                      borderRadius: '8px',
                      '&:hover': {
                        textDecoration: 'none',
                        backgroundColor: 'superLightGray.900'
                      },
                      '&:focus': {
                        outline: 'none',
                        boxShadow: 'none'
                      }
                    },
                    '&.active': {
                      'a': {
                        backgroundColor: 'superLightGray.900',
                        borderLeft: '6px solid #008C81',
                        'img': {
                          'path': {
                            fill: '#008C81 !important'
                          }
                        }
                      }
                    }
                  }
                }"
                >
                <c-list-item
                  marginTop="30px"
                  class="active"
                  >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_user.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="8px"
                    />
                    Data Diet Klien
                  </c-link>
                </c-list-item>
                <c-list-item
                  marginTop="30px"
                 
                  >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_notebook.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="8px"
                    />
                    ADIME Note
                  </c-link>
                </c-list-item>
                <c-list-item
                  marginTop="30px"
                 
                  >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_notes.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="8px"
                    />
                    Follow Up Notes
                  </c-link>
                </c-list-item>
                <c-list-item
                  marginTop="30px"
                 
                  >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_trending.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="8px"
                    />
                    Progress &amp; Tracker
                  </c-link>
                </c-list-item>
                <c-list-item
                  marginTop="30px"
                  >
                  <c-link href="#">
                    <c-image
                      :src="require('@/assets/ic_meat.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="8px"
                    />
                    Meal Planner
                  </c-link>
                </c-list-item>
              </c-list>
            </c-box>
            <c-box w="60%">
              <c-box marginBottom="40px">
                <c-text color="brand.900" fontFamily="roboto" textTransform="uppercase" fontWeight="700" fontSize="18px" marginBottom="10px">Kuisioner</c-text>
                <c-heading fontWeight="600" fontSize="24px" lineHeight="36px" color="black.900">Program Body Goals</c-heading>
              </c-box>
              <c-flex py="30px" justifyContent="space-between" alignItems="center"
                v-chakra="{
                  '.step': {
                    w: '100%',
                    h: '8px',
                    maxWidth: '156px',
                    borderRadius: '20px',
                    backgroundColor: 'superLightGray.900',
                    marginRight: '12px',
                    ':last-child': {
                      marginLeft: '0'
                    },
                    '&.done': {
                      backgroundColor: 'brand.900'
                    }
                  },
                }"
                >
                <c-box class="step done"></c-box>
                <c-box class="step"></c-box>
                <c-box class="step"></c-box>
                <c-box class="step"></c-box>
                <c-box class="step"></c-box>
                <c-box class="step"></c-box>
                <c-box class="step"></c-box>
              </c-flex>
              <c-box>
                <c-heading fontWeight="600" fontSize="18px" lineHeight="27px" color="black.900" marginBottom="30px">Identitas</c-heading>

                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Nama Depan</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Jennie</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Nama Belakang</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Kim</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Nama Tangah</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Ruby</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Jenis Kelamin</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Perempuan</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Usia</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">25 tahun</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Tanggal Lahir</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">25 January 1996</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Alamat</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Jalan Sukun Gang Matahari No 2 Karangbendo Banguntapan Bantul</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Negara</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Indonesia</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Provinsi</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">D.I. Yogyakarta</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Kota</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Slemen</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Kode Pos</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">63371</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Nomor Telepon</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">0812 3456 7890</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Apa tujuan utama kamu?</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Pilih tujuan utama kamu</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Tipikal diet yang kamu harapkan</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Tipikal diet yang kamu harapkan</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Apakah kamu memiliki timbangan berat badan di rumah?</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Apakah kamu memiliki timbangan berat badan di rumah?</c-text>
                </c-box>
                <c-box marginBottom="30px">
                  <c-text color="brand.900" fontFamily="roboto" fontWeight="500" fontSize="16px" marginBottom="8px">Mana yang paling mendeskripsikan kondisi Klien saat ini?</c-text>
                  <c-text color="superDarkGray.900" fontFamily="roboto" fontWeight="400" fontSize="18px">Mana yang paling mendeskripsikan kondisi Klien saat ini?</c-text>
                </c-box>
              </c-box>
              <c-flex justifyContent="space-between" alignItems="center" marginTop="30px">
                <c-button left-icon="chevron-left" backgroundColor="brand.900" color="white" borderRadius="100px" variant="solid" px="30px">
                  Kembali
                </c-button>
                <c-button right-icon="chevron-right" backgroundColor="brand.900" color="white" borderRadius="100px" variant="solid" px="30px">
                  Selanjutnya
                </c-button>
              </c-flex>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from '@/components/header/index.vue'
import Footer from '@/components/Footer.vue'
import VSelect from '@/components/VSelect'

export default {
  name: 'detailClient',
  components: {
    Header,
    Footer,
    VSelect
  }
}
</script>

<style>
</style>
